import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { useIntl, Link } from "@intractive/gatsby-plugin-react-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../../components/Layout'

const PagePortefeuilles = ({ data }) => {
    const intl = useIntl();
    return (
  
  <Layout>
    <Helmet>
        <title>{intl.formatMessage({id:"Portefeuilles"})} · STIP</title>
    </Helmet>
    <div className="row main-content">
      <div className="columns">
        <h1>Portefeuilles</h1>
        <ul className="large-block-grid-3 medium-block-grid-2 small-block-grid-1">
          {data.portefeuilles.edges && data.portefeuilles.edges.map(function({ node }, index){
            const portefeuille = node;
            const url = `/portefeuilles/${portefeuille.slug}/`
            return(
              <li key={index} id={index}>
                <article className="blog-listing-article portefeuilleartikel" itemScope="" itemType="http://schema.org/Article">
                  {portefeuille.header && <Link to={url}><GatsbyImage image={portefeuille.header.gatsbyImageData} alt={portefeuille.header.title} className="blog-image"/></Link>}
                  <strong className="portefeuillenaam"><br/><Link to={url}><span>{portefeuille.titel}</span></Link></strong>
                </article>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  </Layout>
)
}

export default PagePortefeuilles

export const query = graphql`
  query($node_locale: String) {
    portefeuilles: allContentfulPortefeuille(sort: {fields: titel}, filter: {node_locale: {eq: $node_locale}}){
      edges {
        node {
          slug
          titel
          header {
            title
            gatsbyImageData(formats: WEBP, width: 550)
          }
        }
      }
    }
  }
`
